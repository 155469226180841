<template>
  <div class="curtain">
    <!-- 参数 -->
    <Head :name="'窗帘电机'" :add="'/pc/ProCurtain'" :adds="'/pc/ProCurtainParam'" :color="true" />
    <!-- banner -->
    <div class="banner">
      <div class="bannerBox">
        <div class="title">窗帘电机</div>
        <div class="text">性能强劲，体验豪华</div>
        <div class="banLi">一键开合｜智能联动｜支持定制｜语音控制</div>
      </div>
    </div>
    <!-- 体验 -->
    <div class="sec1">
      <div class="sec1Tit">每一次技术革新，只为用户体验</div>
      <div class="sec1Box">
        <div class="ul1">
          <div class="sec1Left">
            <div class="lli">
              <div class="liTit">静音设计，无声运行</div>
              <div class="liText">全新的电机和静音技术，使电机在运行时的噪音控制在22分贝以下。</div>
              <div class="liTexts">窗帘开合，仿佛无声。</div>
            </div>
            <div class="lli">
              <div class="liTit">信号强劲，控制于无形之中</div>
              <div class="liText">全新的自研无线解决方案，使信号覆盖范围与稳定性大大增强，</div>
              <div class="liTexts">就算是强干扰环境也不再话下。</div>
            </div>
          </div>
          <div class="sec1Right">
            <div class="rli">
              <div class="liTit">你的风格，你来定</div>
              <div class="liText">支持定制，还可适应不同的的窗帘和轨道。</div>
              <div class="liTexts">高端体验，为你打造。</div>
            </div>
          </div>
        </div>
        <div class="ul2">* 依据测量方式不同，实际结果可能略有差异，数据来自实验室。</div>
      </div>
    </div>
    <div class="sec2">
      <div class="sec2Box">
        <div class="sec2Tit">用户体验的提升，源自于每一个瞬间的人性</div>
        <div class="sec2Text">智能联动BOT智能照明，智能影音等系统，定制场景，定制体验</div>
        <div class="sec2Ul">
          <div class="ulTit">智能联动，打造入住新体验</div>
          <div class="ulText">刷卡进门，</div>
          <div class="ulText">窗帘自动缓缓推开，</div>
          <div class="ulText">灯光系统自动亮起，</div>
          <div class="ulText">背景音乐轻柔响起。</div>
        </div>
        <div class="sec2Ul">
          <div class="ulTit">晚安好梦，独享五星级的睡眠环境</div>
          <div class="ulText">朦胧睡意，</div>
          <div class="ulText">无线遥控一键关灯，</div>
          <div class="ulText">窗帘自动缓缓关闭，</div>
          <div class="ulText">空调自动调整，</div>
          <div class="ulText">打造睡眠环境，从未如此轻松。</div>
        </div>
      </div>
    </div>
    <div class="sec3">
      <div class="sec3Box">
        <div class="sec3Tit">控制，不止一种方式</div>
        <div class="ul">
          <div class="li">
            <img class="liImg" src="../../../assets/product/curtainIcon1.png" alt />
            <div class="liText">语音指令，智能联动</div>
          </div>
          <div class="li">
            <img class="liImg" src="../../../assets/product/curtainIcon2.png" alt />
            <div class="liText">手机控制，免去繁琐</div>
          </div>
          <div class="li">
            <img class="liImg" src="../../../assets/product/curtainIcon3.png" alt />
            <div class="liText">感应触碰，自动启动</div>
          </div>
          <div class="li">
            <img class="liImg" src="../../../assets/product/curtainIcon4.png" alt />
            <div class="liText">智能组网，拉一边动两边</div>
            <!-- <div class="liText liTexts"></div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="sec4">
      <div class="sec4Tit">简约设计，优雅无形</div>
      <div class="sec4Text">简约设计，超静音窗帘电机可是适配不同种类的窗帘和窗纱款式，搭配不同环境风格。</div>
      <div class="sec4Text sec4Texts">就算是弧形，异形轨道也不再话下。</div>
      <div class="bgc"></div>
    </div>
  </div>
</template>
<script>
import Head from "../../../components/Head.vue";
export default {
  components: {
    Head
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
// banner
.banner {
  width: 100%;
  height: 8rem;
  background: url("../../../assets/product/curtainBanner.png");
  background-size: 100% 100%;
  display: flex;
  font-size: 0.18rem;
  line-height: 0.18rem;
  font-weight: 400;
  .bannerBox {
    width: 12rem;
    height: 2.4rem;
    margin: 0 auto;
    margin-top: 1.61rem;
    color: #333333;
     background:linear-gradient(to right, rgba(255,255,255,.001) 0%, rgba(255,255,255,.4) 50%, rgba(255,255,255,.001) 100%);
    overflow: hidden;
    .title {
      margin-top: 0.39rem;
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
    }
    .text {
      margin-top: 0.39rem;
      font-size: 0.3rem;
      line-height: 0.39rem;
      font-weight: 400;
    }
    .banLi {
      margin-top: 0.23rem;
      font-size: 0.18rem;
      line-height: 0.18rem;
      font-weight: 400;
    }
  }
}
.sec1 {
  width: 100%;
  padding: 1.2rem 0px;
  background-color: #fff;
  color: #333333;
  .sec1Tit {
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
  }
  .sec1Box {
    width: 100%;
    margin-top: 0.79rem;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    padding: 0.8rem 0px;
    .ul1 {
      width: 12rem;
      margin: 0 auto;
      display: flex;
      .sec1Left {
        width: 6.4rem;
        margin-right: 0.3rem;
        .lli {
          width: 6.4rem;
          height: 4rem;
          background-image: url("../../../assets/product/curtain1.png");
          background-size: 100% 100%;
          margin-bottom: 0.3rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 0.18rem;
          line-height: 0.18rem;
          font-weight: 400;
          color: #333333;
          &:last-of-type {
            background-image: url("../../../assets/product/curtain2.png");
            margin-bottom: 0px;
          }
          .liTit {
            font-size: 0.24rem;
            line-height: 0.24rem;
            font-weight: bold;
            color: #ff9000;
          }
          .liText {
            margin-top: 0.2rem;
          }
          .liTexts {
            margin-top: 0.12rem;
          }
        }
      }
      .sec1Right {
        width: 5.3rem;
        height: 8.3rem;
        background-image: url("../../../assets/product/curtain3.png");
        background-size: 100% 100%;
        font-size: 0.18rem;
        line-height: 0.18rem;
        font-weight: 400;
        color: #333333;
        .liTit {
          margin-top: 0.4rem;
          font-size: 0.24rem;
          line-height: 0.24rem;
          font-weight: bold;
          color: #ff9000;
        }
        .liText {
          margin-top: 0.2rem;
        }
        .liTexts {
          margin-top: 0.12rem;
        }
      }
    }
    .ul2 {
      margin-top: 0.41rem;
      font-size: 0.1rem;
      line-height: 0.1rem;
      font-weight: 300;
      color: #333333;
    }
  }
}
.sec2 {
  width: 100%;
  background-color: #fff;
  .sec2Box {
    width: 12rem;
    margin: 0 auto;

    .sec2Tit {
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #333333;
    }
    .sec2Text {
      margin-top: 0.39rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
    }
    .sec2Ul {
      width: 100%;
      height: 6rem;
      margin-top: 0.8rem;
      color: #ffffff;
      background-image: url("../../../assets/product/curtain4.png");
      background-size: 100% 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 0.18rem;
      line-height: 0.18rem;
      &:last-of-type {
        background-image: url("../../../assets/product/curtain5.png");
        margin-top: 0.4rem;
        .ulText {
          margin-bottom: 0.03rem;
        }
      }
      .ulTit {
        padding-left: 0.41rem;
        margin-top: 4.09rem;
        margin-bottom: 0.19rem;
        font-size: 0.24rem;
        line-height: 0.24rem;
        font-weight: 800;
      }
      .ulText {
        padding-left: 0.41rem;
        margin-bottom: 0.1rem;
      }
    }
  }
}
.sec3 {
  width: 100%;
  background-color: #fff;
  padding-top: 1.2rem;
  .sec3Box {
    width: 12rem;
    margin: 0 auto;
    color: #333333;
    .sec3Tit {
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
    }
    .ul {
      margin-top: 0.79rem;
      display: flex;
      justify-content: space-between;
      .li {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        .liImg {
          width: 1.35rem;
          height: 1.35rem;
        }
        .liText {
          margin-top: 0.4rem;
          font-size: 0.24rem;
          line-height: 0.24rem;
          font-weight: 400;
          color: #333333;
        }
        .liTexts {
          margin-top: 0.12rem;
        }
      }
    }
  }
}
.sec4 {
  margin-top: 0.81rem;
  background-color: #fff;
  color: #333333;
  .sec4Tit {
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
  }
  .sec4Text {
    font-size: 0.24rem;
    line-height: 0.24rem;
    font-weight: 400;
    color: #333333;
    margin-top: 0.39rem;
  }
  .sec4Texts {
    margin-top: 0.12rem;
  }
  .bgc {
    margin-top: 0.8rem;
    width: 100%;
    height: 6rem;
    background: url("../../../assets/product/curtain6.png") ;
    background-size: 100% 100%;
  }
}
</style>

